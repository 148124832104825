import {ApplicationConfig, LOCALE_ID} from '@angular/core';
import {provideRouter, withComponentInputBinding} from '@angular/router';

import {routes} from './app.routes';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideAnimations} from "@angular/platform-browser/animations";
import {GALLERY_CONFIG, GalleryConfig} from "ng-gallery";
import {DatePipe, DecimalPipe} from "@angular/common";
import {AuthenticationInterceptor} from "@app/authentication.interceptor";
import {LIGHTBOX_CONFIG, LightboxConfig} from "ng-gallery/lightbox";
import config from 'devextreme/core/config';

config({ licenseKey: "ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjc5NjBhZjE1LTJiMDQtMTFlNS04MGNjLTAwMTU1ZDYyNDcwMSIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQyCn0=.pBrH3/tEL7G475vMxDX6grCy+vPAODm+MVvSTfT434PpZOFbkT7qhKnDXnjLO5Nsul8ojMmTOQ+vV7Hnes1Nx1jSn4uUKo8JPjo/G4ly4nOYWCPVUy1B150HIdOB49JzzwVYpA==" });


/**
 * Application configuration object.
 */
export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes, withComponentInputBinding()), provideHttpClient(withInterceptorsFromDi()), provideAnimations(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de'
    },
    {
      provide: GALLERY_CONFIG,
      useValue: {
        autoHeight: false,
        imageSize: 'cover'
      } as GalleryConfig,
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: true,
        exitAnimationTime: 200
      } as LightboxConfig
    },
    DecimalPipe,
    DatePipe,
  ]
};
