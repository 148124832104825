import {Routes} from '@angular/router';
import {authGuard} from '@app/auth.guard';

/**
 * Represents the routes configuration for the application.
 */
export const routes: Routes = [
  { path: '', redirectTo: '/properties', pathMatch: 'full' },
  {
    path: '',
    canActivate: [authGuard],
    loadComponent: () => import('src/app/sites/main.component'),
    children: [
      {
        path: 'properties',
        loadComponent: () =>
          import('src/app/sites/comparables/comparables.component'),
      },
      {
        path: 'markets',
        loadComponent: () =>
          import('src/app/sites/markets/markets.component'),
      },
      {
        path: 'actors',
        loadComponent: () => import('src/app/sites/actors/actors.component'),
      },
      {
        path: 'settings',
        loadComponent: () =>
          import('src/app/sites/settings/settings.component'),
      },
      {
        path: 'offers',
        loadComponent: () => import('src/app/sites/offers/offer.component'),
      },
      {
        path: 'new-offer',
        loadComponent: () =>
          import('src/app/sites/create-offer/create-offer.component'),
      },
      {
        path: 'new-property',
        loadComponent: () =>
          import('src/app/sites/create-property/create-property.component'),
      },
      {
        path: 'properties/:id',
        loadComponent: () =>
          import('src/app/sites/property/property.component'),
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () => import('src/app/sites/login/login.component'),
  },
];
